import React from 'react';
import { GroupedColumn } from '@ant-design/charts';
import exchangeLogo from '../../assets/image/qidiImg/exchangeLogo.png'
import lineDotLogo from '../../assets/image/qidiImg/lineDotLogo.png'
import dataCountLogo from '../../assets/image/qidiImg/dataCountLogo.png'
import './index.less'

const showData = [
  { img: lineDotLogo, name: '链上节点', count: '1,825,511' },
  { img: exchangeLogo, name: '交换量', count: '2,255,523' },
  { img: dataCountLogo, name: '数据量', count: '3,235,532' },
]
const data = [
  {
    name: '链上节点',
    month: '21日',
    data: 765,
  },
  {
    name: '链上节点',
    month: '22日',
    data: 501,
  },
  {
    name: '链上节点',
    month: '23日',
    data: 872,
  },
  {
    name: '链上节点',
    month: '24日',
    data: 839,
  },
  {
    name: '链上节点',
    month: '25日',
    data: 897,
  },
  
  {
    name: '交换量',
    month: '21日',
    data: 689,
  },
  {
    name: '交换量',
    month: '22日',
    data: 850,
  },
  {
    name: '交换量',
    month: '23日',
    data: 1032,
  },
  {
    name: '交换量',
    month: '24日',
    data: 997,
  },
  {
    name: '交换量',
    month: '25日',
    data: 903,
  },

  {
    name: '数据量',
    month: '21日',
    data: 832,
  },
  {
    name: '数据量',
    month: '22日',
    data: 901,
  },
  {
    name: '数据量',
    month: '23日',
    data: 610,
  },
  {
    name: '数据量',
    month: '24日',
    data: 1430,
  },
  {
    name: '数据量',
    month: '25日',
    data: 1133,
  },
  
];

const config = {
  forceFit: true,
  data,
  height: 200,
  xField: 'month',
  yField: 'data',
  yAxis: {
    min: 0,
    title: {
      visible: false
    },
    grid: {
      line: {
        type: "line",
        style: {
          lineDash: [5, 5],
          stroke: '#0C2C6C'
        },
      },
    },
    tickCount: 3,
  },
  label: {
    visible: false,
  },
  groupField: 'name',
  color: ['#0E45D8', '#2C7FF8', '#CEE3FF'],
  // color: ['#5246FF', '#5E84ED', '#C8D8FF'],
  legend: {
    position: "bottom-center",
    text:{
      style:{
        fill:'#fff'
      }
    },
    marker: {
      symbol: "square",
      style: {
        r: 5
      }      
    },
    
    offsetY: 5,
  },
  xAxis: {
    title: {
      visible: false,
    }
  },
  columnSize: 7
}

const Screen3LeftTop = prop => {
  const top = showData.map((ele, index) => {
    return (
      <div key={index.toString()}>
        <div className="titleArea">
          <img src={ele.img} alt={ele.name} />
          <div>{ele.name}</div>
        </div>
        <div className="count">{ele.count}</div>
      </div>
    )
  })
  return (
    <div className='screen3LeftTop'>
      <div className='screen3LeftTop-topArea'>{top}</div>
      <div>
        <GroupedColumn {...config} />
      </div>
    </div>
  )
}

export default Screen3LeftTop