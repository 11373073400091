import React, { Component } from "react";
import { Select } from "antd";
import CenterStatistic from "../../components/CenterStatistic";
import Header from "../../components/Header";
import Screen3LeftTop from "../../components/Screen3LeftTop";
import Screen3LeftBottom from "../../components/Screen3LeftBottom";
import Screen3RightMiddle from "../../components/Screen3RightMiddle";
import MyTable from "../../components/MyTable";
import "antd/dist/antd.css";
import "./index.less";
import monitorData from "../../morkData/qidiScreen3Data";

const { Option } = Select;

const zoneSelectData = [
  { name: "区域1", id: "zone1" },
  { name: "区域2", id: "zone2" },
  { name: "区域3", id: "zone3" },
  { name: "区域4", id: "zone4" },
];

let TIMER = null;

class index extends Component {
  constructor(props) {
    super();
    this.state = {
      curKey: 0,
      statisticData: [
        { title: "告警事件总数", num: "3,725,532", unit: "(个)" },
        { title: "入侵检测", num: "1,325,823", unit: "(个)" },
        { title: "病毒防护", num: "2,520,423", unit: "(个)" },
      ],
      columns: [
        {
          title: "序号",
          key: "num",
          dataIndex: "key",
        },
        {
          title: "时间",
          dataIndex: "time",
          key: "time",
        },
        {
          title: "节点名称",
          dataIndex: "nodeName",
          key: "nodeName",
        },
        {
          title: "IP地址",
          dataIndex: "ip",
          key: "ip",
        },
        {
          title: "告警类型",
          dataIndex: "warnType",
          key: "warnType",
        },
        {
          title: "告警描述",
          dataIndex: "warnDes",
          key: "warnDes",
        },
      ],
      tableData: [
        {
          key: "1",
          time: "2020-09-25  90:00",
          nodeName: "探针1",
          ip: "192.168.20.37",
          warnType: "CPU负载",
          warnDes: "当前负载过量，超过告警基线...",
        },
        {
          key: "2",
          time: "2020-09-22  10:37",
          nodeName: "防护墙1",
          ip: "192.168.20.37",
          warnType: "网卡状态",
          warnDes: "当前负载过量，超过告警基线...",
        },
        {
          key: "3",
          time: "2020-09-12  0:04",
          nodeName: "探针2",
          ip: "192.168.20.37",
          warnType: "磁盘使用率",
          warnDes: "当前负载过量，超过告警基线...",
        },
      ],
      zoneKey: "zone1",
    };
  }

  onZoneChange = (value) => {
    this.setState({ zoneKey: value });
  };

  autoMove = (maxNum) => {
    const { curKey } = this.state;
    const { time } = this.props;
    clearInterval(TIMER);

    const num = curKey === maxNum - 1 ? 0 : curKey + 1;
    TIMER = setInterval(() => {
      this.changeIndex(num);
    }, time || 5000);
  };

  changeIndex = (key) => {
    this.setState({
      curKey: key,
    });
  };

  changeCur = (key) => {
    clearInterval(TIMER);
    this.setState({
      curKey: key,
    });
  };

  render() {
    const { statisticData, columns, tableData, zoneKey, curKey } = this.state;
    const data = monitorData;
    const buildList = data.map((ele, index) => {
      const isactivity = index === curKey;
      return (
        <p
          className="buildingName"
          isactivity={String(isactivity)}
          onClick={() => this.changeCur(index)}
          key={index.toString()}
        >
          {ele.roomName}
        </p>
      );
    });
    const curData = data[curKey].status || [];
    const cpu = (curData.find((ele) => ele.name === "CPU占有率") || {}).value;
    const storage = (curData.find((ele) => ele.name === "内存使用量") || {})
      .value;
    const net = (curData.find((ele) => ele.name === "宽带流量") || {}).value;
    const temp = (curData.find((ele) => ele.name === "温度") || {}).value;
    const wet = (curData.find((ele) => ele.name === "湿度") || {}).value;

    const dotList = data.map((ele, index) => {
      const active = index === curKey;
      const { dotposition, dotpositionAct, namePosition, linePosition } = ele;
      const dotStyle = active ? dotpositionAct : dotposition;
      return (
        <div>
          {(active && (
            <div>
              <div
                isactivity={String(active)}
                className="roomNamePositon"
                style={{ ...namePosition }}
              >
                {ele.roomName}
              </div>
            </div>
          )) ||
            null}

          {active && (
            <div className="linePosition" style={{ ...linePosition }}></div>
          )}
          <div
            className="dotStylePositon"
            style={{ ...dotStyle }}
            isactivity={String(active)}
            onClick={() => this.changeCur(index)}
          />
        </div>
      );
    });

    this.autoMove(data.length);
    return (
      <>
        <div>
          <div className="wrapper3">
            <Header></Header>
            <div className="screen3_content">
              <div className="content_center">
                <CenterStatistic statisticData={statisticData} />
              </div>
              <div className=""></div>
              <section className="left">
                <div className="left_top">
                  {" "}
                  <div className="title">链上数据统计</div>
                  <div className="info">
                    <Screen3LeftTop />
                  </div>
                </div>
                <div className="left_bottom">
                  <div className="title">贡献</div>
                  <div className="info">
                    <Screen3LeftBottom />
                  </div>
                </div>
              </section>
              <section className="right">
                <div className="item">
                  <div className="title">基础设施监测</div>
                  <div className="info">
                    <div className="info_left">
                      {/* <p className="active">三号楼机房</p>
                      <p>二号楼11#</p>
                      <p>5#机房</p>
                      <p>三号楼机房</p>
                      <p>二号楼13#</p> */}
                      {buildList}
                    </div>
                    <div className="info_right">
                      <div className="a">
                        {" "}
                        <p>CPU占有率 </p>
                        <p>内存使用量 </p>
                        <p>宽带流量 </p>
                        <p>温度 </p>
                        <p>湿度 </p>
                      </div>
                      <div className="b">
                        <p>{cpu}</p>
                        <p>{storage}</p>
                        <p>{net}</p>
                        <p>{temp}</p>
                        <p>{wet}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="realTimeWatch">
                    <div className="zoneSelect">
                      <Select
                        value={zoneKey}
                        onChange={this.onZoneChange}
                        style={{ width: "120px", marginTop: "10px" }}
                      >
                        {zoneSelectData.map((ele) => (
                          <Option key={ele.id} value={ele.id}>
                            {ele.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="title">实时监控</div>
                  </div>
                  <div className="info">
                    <Screen3RightMiddle data={zoneKey} />
                  </div>
                </div>
              </section>
              <div className="bottom_title">待解决告警事件</div>
              <div className="bottom">
                <div className="info">
                  <MyTable columns={columns} tableData={tableData} />
                </div>
              </div>
              <div>{dotList}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default index;
