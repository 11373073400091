import React from "react";
import { Radar } from "@ant-design/charts";
import "./index.less";

const Screen1RightBottom = (props) => {
  const { data } = props;
  const score = (data || []).reduce((p, n) => p + (n.score || 0), 0);
  const avaScore = Math.floor(score / 5);
  const config = {
    data,
    height: 270,
    angleField: "item",
    radiusField: "score",
    seriesField: "user",
    radiusAxis: {
      grid: {
        line: {
          type: "line",
          style: {
            lineDash: [5, 5],
          },
        },
      },
      tickLine: {
        visible: false,
      },
      label: {
        visible: false,
      },
      tickCount: 5,
      max: 100,
    },
    line: {
      visible: true,
      style: {
        stroke: "#8EA4DC",
        // stroke: "#307BFF",
      },
    },
    point: {
      visible: true,
      shape: "circle",
      size: 4,
      style: {
        fill: "#ffffff",
        // fill: "#307BFF",
      },
    },
    legend: {
      visible: false,
    },
    area: {
      visible: true,
      style: {
        opacity: 0.25,
        fill:'l(90) 0:#D2E7FF 1:#2B7CF0'
        // fill: "#307BFF",
      },
    },
    meta: {
      score: {
        alias: "分数",
      },
    },
  };
  return (
    <div className="screen1RightBottom">
      <div className="screen1RightBottom-scoreArea">
        <div className="screen1RightBottom-scoreArea-title">综合得分</div>
        <div className="screen1RightBottom-scoreArea-score">{avaScore}分</div>
      </div>
      <Radar {...config} />
    </div>
  );
};

export default Screen1RightBottom;
