import React from "react";
import { ConfigProvider } from "antd";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import zh_CN from "antd/lib/locale-provider/zh_CN";
// import ControlCenter from "./pages/controlCenter";
import "moment/locale/zh-cn";
// import ParkData from './pages/parkData'
import QidiScreen1 from "./pages/qidiScreen1";
import QidiScreen2 from "./pages/qidiScreen2/index";
import QidiScreen3 from "./pages/qidiScreen3/index";

const App = (
  <ConfigProvider locale={zh_CN}>
    <BrowserRouter>
      <Switch>
        {/* <Route path="/park/:key/:id" component={ParkData} />         */}        
        <Route path="/qidiScreen1" component={QidiScreen1} />
        <Route path="/qidiScreen2" component={QidiScreen2} />
        <Route path="/qidiScreen3" component={QidiScreen3} />
        <Route exact path="/" component={QidiScreen1} />
      </Switch>
    </BrowserRouter>
  </ConfigProvider>
);

export default App;
