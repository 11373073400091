import React from 'react';
import zone1 from '../../assets/image/qidiImg/zone1.png'
import zone11 from '../../assets/image/qidiImg/zone1-1.png'
import zone2 from '../../assets/image/qidiImg/zone2.png'
import zone22 from '../../assets/image/qidiImg/zone2-1.png'
import zone3 from '../../assets/image/qidiImg/zone3.png'
import zone33 from '../../assets/image/qidiImg/zone3-1.png'
import zone4 from '../../assets/image/qidiImg/zone4.png'
import zone44 from '../../assets/image/qidiImg/zone4-1.png'
import './index.less'

const imgData = {
  zone1: {left: zone1, right: zone11 },
  zone2: {left: zone2, right: zone22 },
  zone3: {left: zone3, right: zone33 },
  zone4: {left: zone4, right: zone44 },
}

const Screen3RightMiddle = props => {
  const { data } = props
  const { left, right } = imgData[data] || {}
  return (
    <div className='screen3RightMiddle'>
      <div className="zoneImgShowArea">
        <div className='imgShowArea'>
          <img src={left} alt='left' style={{width: '186px', height: '126px'}} />
          <div className='imgShowAreaMask'>
            <div className="onlineButton">在线</div>
          </div>
        </div>
        <div className='imgShowArea'>
          <img src={right} alt='left' style={{width: '186px', height: '126px'}} />
          <div className='imgShowAreaMask' offline='true'>
            <div className="onlineButton" offline='true'>离线</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Screen3RightMiddle