const monitorData = [
  {
    roomName: "三号楼机房",
    status: [
      {
        name: "CPU占有率",
        value: "15%",
      },
      {
        name: "内存使用量",
        value: "70%",
      },
      {
        name: "宽带流量",
        value: "100M",
      },
      {
        name: "温度",
        value: "25℃",
      },
      {
        name: "湿度",
        value: "42%",
      },
    ],
    dotposition: { top: "335px", left: "547px" },
    dotpositionAct: { top: "329px", left: "541px" },
    namePosition: { top: "224px", left: "496px" },
    linePosition: { top: "286px", left: "551px" },
  },
  {
    roomName: "二号楼11#",
    status: [
      {
        name: "CPU占有率",
        value: "13%",
      },
      {
        name: "内存使用量",
        value: "76%",
      },
      {
        name: "宽带流量",
        value: "100M",
      },
      {
        name: "温度",
        value: "26℃",
      },
      {
        name: "湿度",
        value: "43%",
      },
    ],
    dotposition: { top: "374px", left: "565px" },
    dotpositionAct: { top: "368px", left: "559px" },
    namePosition: { top: "255px", left: "520px" },
    linePosition: { top: "321px", left: "570px" },
  },
  {
    roomName: "5#机房",
    status: [
      {
        name: "CPU占有率",
        value: "11%",
      },
      {
        name: "内存使用量",
        value: "45%",
      },
      {
        name: "宽带流量",
        value: "100M",
      },
      {
        name: "温度",
        value: "23℃",
      },
      {
        name: "湿度",
        value: "23%",
      },
    ],
    dotposition: { top: "400px", left: "600px" },
    dotpositionAct: { top: "394px", left: "594px" },
    namePosition: { top: "282px", left: "543px" },
    linePosition: { top: "347px", left: "603px" },
  },
  {
    roomName: "三号楼机房",
    status: [
      {
        name: "CPU占有率",
        value: "11%",
      },
      {
        name: "内存使用量",
        value: "46%",
      },
      {
        name: "宽带流量",
        value: "100M",
      },
      {
        name: "温度",
        value: "23℃",
      },
      {
        name: "湿度",
        value: "48%",
      },
    ],
    dotposition: { top: "400px", left: "700px" },
    dotpositionAct: { top: "394px", left: "694px" },
    namePosition: { top: "285px", left: "641px" },
    linePosition: { top: "347px", left: "704px" },
  },
  {
    roomName: "二号楼13#",
    status: [
      {
        name: "CPU占有率",
        value: "23%",
      },
      {
        name: "内存使用量",
        value: "23%",
      },
      {
        name: "宽带流量",
        value: "100M",
      },
      {
        name: "温度",
        value: "22℃",
      },
      {
        name: "湿度",
        value: "22%",
      },
    ],
    dotposition: { top: "425px", left: "676px" },
    dotpositionAct: { top: "419px", left: "670px" },
    namePosition: { top: "309px", left: "621px" },
    linePosition: { top: "375px", left: "679px" },
  },
];
export default monitorData;
