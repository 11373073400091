import React, { Component } from "react";
import { Carousel, Divider } from "antd";
import DATA from "../../morkData/qidiCompanyMatchData";
import "./index.less";
import "antd/dist/antd.css";

let CarouselTimer = null;

class Screen2BottomCerter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curIndex: 0,
    };
    this.chooseIndex = null;
  }

  componentWillUnmount() {
    clearInterval(CarouselTimer);
  }

  shouldComponentUpdate(nextProp, nextState) {
    const { curIndex } = this.state;
    const { curIndex: nextIndex } = nextState;
    return curIndex !== nextIndex;
  }

  autoMove = (maxNum) => {
    const { curIndex } = this.state;
    const { time } = this.props;
    clearInterval(CarouselTimer);

    const num = curIndex === maxNum - 1 ? 0 : curIndex + 1;
    CarouselTimer = setInterval(() => {
      this.changePage(num);
    }, time || 5000);
  };

  changePage = (index) => {
    this.setState({ curIndex: index });
    // this.chooseIndex.goTo(index)
    this.chooseIndex.next();
  };

  render() {
    const list = DATA.map((ele, index) => (
      <div key={index.toString()} className="matchPageItem">
        {ele.map((item, num) => {
          return (
            <div key={num.toString()}>
              {(num !== 0 && (
                <Divider style={{ margin: "13px 0", background: "#0C2C6C" }} />
              )) ||
                null}
              <div className="matchItem">
                <div className="matchName">{`【 ${item.name} 】`}</div>
                <div>
                  匹配度：
                  <span className="matchDegree">{item.matchDegree}</span>%
                </div>
              </div>
            </div>
          );
        })}
      </div>
    ));

    this.autoMove(DATA.length);
    return (
      <div className="screen2BottomCerter">
        <Carousel
          dots={false}
          ref={(el) => {
            this.chooseIndex = el;
          }}
          dotPosition="left"
        >
          {list}
        </Carousel>
      </div>
    );
  }
}

export default Screen2BottomCerter;
