import React, { Component } from "react";
import CenterStatistic from "../../components/CenterStatistic";
import Header from "../../components/Header";
import MyTable from "../../components/MyTable";
import { data } from "../../morkData/qidiScreen2Data";
import Screen2BottomCerter from "../../components/Screen2BottomCenter";
import "./index.less";
class index extends Component {
  constructor(props) {
    super();
    this.state = {
      curCompanyKey: 0,
      mapDotData: {
        key: 0,
        name: "武汉启迪区块链科技发展有限公司",
      },
      statisticData: [
        { title: "累计扶持企业(家)", num: 33 },
        { title: "扶持申请金额(亿万元)", num: 552 },
        { title: "累计审批金额(亿万元)", num: 552 },
      ],
      columns: [
        {
          title: "入选企业",
          dataIndex: "firm",
          key: "firm",
        },
        {
          title: "入选机构",
          dataIndex: "organization",
          key: "organization",
        },
      ],
      position: { left: "591px", top: "250px" },
    };
  }
  companyChange = (index) => {
    index = Number(index);
    console.log(index);
    this.setState({ curCompanyKey: index });
  };

  changeCur = (key) => {
    this.setState({
      curCompanyKey: key,
    });
  };
  render() {
    const { statisticData, columns, curCompanyKey } = this.state;
    const tableData = data;
    console.log(tableData);

    const dotList = data.map((ele, index) => {
      const active = index === curCompanyKey;
      const { dotposition, dotpositionAct, namePosition, linePosition } = ele;
      const dotStyle = active ? dotpositionAct : dotposition;
      return (
        <div>
          {(active && (
            <div>
              <div
                isactivity={String(active)}
                className="roomNamePositon"
                style={{ ...namePosition }}
              >
                {ele.firm}
              </div>
            </div>
          )) ||
            null}

          {active && (
            <div className="linePosition" style={{ ...linePosition }}></div>
          )}
          <div
            className="dotStylePositon"
            style={{ ...dotStyle }}
            isactivity={String(active)}
            onClick={() => this.changeCur(index)}
          />
        </div>
      );
    });

    return (
      <>
        <div>
          <div className="wrapper2">
            <Header></Header>
            <div className="screen2_content">
              <div className="content_center">
                <CenterStatistic statisticData={statisticData} />
              </div>
              <div className=""></div>
              <section className="left">
                <div className="left_top">
                  {" "}
                  <div className="title">国家网络安全基地孵化器</div>
                  <div className="info">
                    <MyTable
                      curKey={curCompanyKey}
                      onChange={this.companyChange}
                      columns={columns}
                      tableData={tableData}
                    />
                  </div>
                </div>
                <div className="left_bottom">
                  <div className="title">{tableData[curCompanyKey].firm}</div>
                  <div className="info">
                    <img src={tableData[curCompanyKey].imgUrl} alt="" />
                  </div>
                </div>
              </section>
              <section className="right">
                <div className="item">
                  <div className="title">项目扶持情况</div>
                  <div className="info"></div>
                </div>
                <div className="item">
                  <div className="title">园内企业审批情况</div>
                  <div className="info"></div>
                </div>
                <div className="item">
                  <div className="title">启迪园区政策</div>
                  <div className="info">
                    <div>
                      <div className="policyTitleName">关于加快推进企业境内外上市融资政策的通知</div>
                      <div className="policySource">来源：武汉临空港开发区管委员（东西湖区政府）</div>
                    </div>
                    <span className="line"></span>
                    <div>
                      <div className="policyTitleName">关于武汉中小微企业、个体工商户“301”贷款申请通知</div>
                      <div className="policySource">来源：汉口银行、武汉农村商业银行</div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="bottom">
                <div className="title">与高校潜在合作科技成果</div>
                <div className="info">
                  <Screen2BottomCerter />
                </div>
              </section>
              <div>{dotList}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default index;
