import React from "react";
import { Divider } from "antd";
import "./index.less";
// import "antd/dist/antd.css";

const data = [
  {
    name: "人人贷商务顾问（北京）有限公司",
    legalPerson: "杨一夫",
    registeredCapital: "200000",
    investmentValue: "95,523",
  },
  {
    name: "北京中文在线教育科技发展有限公司",
    legalPerson: "童之磊",
    registeredCapital: "8058",
    investmentValue: "72,114",
  },
  {
    name: "布比（北京）网络技术有限公司",
    legalPerson: "蒋海",
    registeredCapital: "1344",
    investmentValue: "70,608",
  },
];

const Screen3LeftBottom = (props) => {
  const list = data.map((ele, index) => {
    const needDivide = index < data.length - 1;
    return (
      <div key={index.toString()}>
        <div className="listItem">
          <div className="titleArea">
            <div className="listOrder">
              <div className="listOrderItem">NO.{index + 1}</div>
              </div>
            <div>{ele.name}</div>
          </div>
          <div className="dataArea">
            <div className="dataItem">
              注册资本
              <span className="dataValue">{ele.registeredCapital}万元</span>
            </div>
            <div className="dataItem">
              法人<span className="dataValue">{ele.legalPerson}</span>
            </div>
            <div className="dataItem">
              贡献量<span className="dataValue">{ele.investmentValue}项</span>
            </div>
          </div>
        </div>
        {(needDivide && (
          <Divider style={{ margin: "18px 0", background: "#0C2C6C" }} />
        )) ||
          null}
      </div>
    );
  });
  return <div className="screen3LeftBottom">{list}</div>;
};

export default Screen3LeftBottom;
