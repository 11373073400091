import threedeg1 from "../assets/image/qidiImg/three-deg1.png";
import threedeg2 from "../assets/image/qidiImg/three-deg2.png";
import threedeg3 from "../assets/image/qidiImg/three-deg3.png";
import threedeg4 from "../assets/image/qidiImg/three-deg4.png";
import threedeg5 from "../assets/image/qidiImg/three-deg5.png";
const data = [
  {
    key: "1",
    firm: "武汉启迪区块链科技发展有限公司",
    organization: "汉口银行",
    imgUrl: threedeg1,
    dotposition: { top: "335px", left: "547px" },
    dotpositionAct: { top: "329px", left: "541px" },
    namePosition: { top: "222px", left: "497px" },
    linePosition: { top: "280px", left: "551px" },
  },
  {
    key: "2",
    firm: "启迪汇智 科技发展（武汉）有限公司",
    organization: "建设银行",
    imgUrl: threedeg2,
    dotposition: { top: "303px", left: "559px" },
    dotpositionAct: { top: "297px", left: "553px" },
    namePosition: { top: "170px", left: "517px" },
    linePosition: { top: "238px", left: "563px" },
  },
  {
    key: "3",
    firm: "启迪网安和众科技发展（武汉）有限公司",
    organization: "建设银行",
    imgUrl: threedeg3,
    dotposition: { top: "289px", left: "594px" },
    dotpositionAct: { top: "283px", left: "588px" },
    namePosition: { top: "164px", left: "541px" },
    linePosition: { top: "224px", left: "598px" },
  },
  {
    key: "4",
    firm: "长沙手之声信息科技有限公司",
    organization: "武汉农村商业银行",
    imgUrl: threedeg4,
    dotposition: { top: "284px", left: "694px" },
    dotpositionAct: { top: "278px", left: "688px" },
    namePosition: { top: "155px", left: "641px" },
    linePosition: { top: "217px", left: "697px" },
  },
  {
    key: "5",
    firm: "中云网安科技（北京）有限公司",
    organization: "建设银行",
    imgUrl: threedeg5,
    dotposition: { top: "425px", left: "676px" },
    dotpositionAct: { top: "419px", left: "670px" },
    namePosition: { top: "295px", left: "618px" },
    linePosition: { top: "359px", left: "679px" },
  },
];

export { data };
