import React, { Component } from 'react'
import './index.less'

let TIMER = null

const clickData = [
  { top: '21px', left: "109px" },  
  { top: '201px', left: "193px" },
  { top: '201px', left: "21px" },
]

class Screen1LeftBottom extends Component {
  constructor(props) {
    super(props)
    const { curAreaKey } = props
    this.state = {
      areaKey: curAreaKey,
      curIndex: 0,
    }
  }

  componentDidUpdate(preProps) {
    const { curAreaKey: preAreaKey } = preProps
    const { curAreaKey } = this.props
    if (preAreaKey !== curAreaKey) {
      this.reset(curAreaKey)
    }
  }

  componentWillUnmount() {
    clearInterval(TIMER);
  }

  reset = key => {
    clearInterval(TIMER);
    this.setState({ areaKey: key, curIndex: 0 })
  }

  typeClick = index => {
    clearInterval(TIMER);
    this.setState({ curIndex: index });
  }

  shiftConf = index => {
    this.setState({ curIndex: index });
  }

  autoMove = MaxNum => {
    clearInterval(TIMER);
    const { curIndex } = this.state
    const nextIndex = curIndex === MaxNum? 0 : curIndex + 1

    TIMER = setInterval(() => {
      this.shiftConf(nextIndex);
    }, 5000);
  }

  render() {
    const { data } = this.props
    const { curIndex } = this.state
    const curImgObj = (data || [])[curIndex] || null

    const clickList = clickData.map((ele, index) => (
      <div 
        key={index.toString()} 
        className="typeClickArea"
        style={{ ...ele }}
        onClick={() => this.typeClick(index)}
      />
    ))

    this.autoMove(data.length-1);

    return (
      <div className="screen1LeftBottom">
        {(curImgObj && (
          <img src={curImgObj.img} alt={curImgObj.key} />
        )) || null}
        <div>{clickList}</div>
      </div>
    )
  }
}

export default Screen1LeftBottom