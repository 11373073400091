
import hubeiPipei from "../assets/image/qidiImg/hubeiPipei.png";
import beijingPipei from "../assets/image/qidiImg/beijingPipei.png";
import shanghaiPipei from "../assets/image/qidiImg/shanghaiPipei.png";
import hubeiDataSafe from "../assets/image/qidiImg/hubeiDataSafe.png";
import hubeiPhySafe from "../assets/image/qidiImg/hubeiPhySafe.png";
import hubeiRunSafe from "../assets/image/qidiImg/hubeiRunSafe.png";
import beijingDataSafe from "../assets/image/qidiImg/beijingDataSafe.png";
import beijingPhySafe from "../assets/image/qidiImg/beijingPhySafe.png";
import beijingRunSafe from "../assets/image/qidiImg/beijingRunSafe.png";
import shanghaiDataSafe from "../assets/image/qidiImg/shanghaiDataSafe.png";
import shanghaiPhySafe from "../assets/image/qidiImg/shanghaiPhySafe.png";
import shanghaiRunSafe from "../assets/image/qidiImg/shanghaiRunSafe.png";


const data = {
  hubei: {
    name: '湖北省',
    shortName: '湖北',
    company: [
      { 
        key: '1', 
        firm: '紫光股份有限公司', 
        status: '上游', 
        matched: '94%', 
        score: [
          { item: "资产运营", score: 90 },
          { item: "区域影响力", score: 98 },
          { item: "市场占有率", score: 97 },
          { item: "技术更新水平", score: 94 },
          { item: "营业增长率", score: 94 },
        ]
      },
      { 
        key: '2', 
        firm: '武汉启迪区块链科技发展有限公司', 
        status: '上游', 
        matched: '94%', 
        score: [
          { item: "资产运营", score: 80 },
          { item: "区域影响力", score: 88 },
          { item: "市场占有率", score: 87 },
          { item: "技术更新水平", score: 84 },
          { item: "营业增长率", score: 84 },
        ]
      },
      { 
        key: '3', 
        firm: '启迪汇智科技发展（武汉）有限公司', 
        status: '中游', 
        matched: '92%', 
        score: [
          { item: "资产运营", score: 70 },
          { item: "区域影响力", score: 98 },
          { item: "市场占有率", score: 87 },
          { item: "技术更新水平", score: 94 },
          { item: "营业增长率", score: 74 },
        ]
      },
      { 
        key: '4', 
        firm: '启迪网安和众科技发展（武汉）有限公司', 
        status: '上游', 
        matched: '92%', 
        score: [
          { item: "资产运营", score: 90 },
          { item: "区域影响力", score: 78 },
          { item: "市场占有率", score: 97 },
          { item: "技术更新水平", score: 74 },
          { item: "营业增长率", score: 94 },
        ]
      },
      { 
        key: '5', 
        firm: '手之声信息科技有限公司', 
        status: '中游', 
        matched: '90%', 
        score: [
          { item: "资产运营", score: 89 },
          { item: "区域影响力", score: 98 },
          { item: "市场占有率", score: 60 },
          { item: "技术更新水平", score: 100 },
          { item: "营业增长率", score: 70 },
        ]
      },
      { 
        key: '6', 
        firm: '中云网安科技有限公司', 
        status: '下游', 
        matched: '89%', 
        score: [
          { item: "资产运营", score: 100 },
          { item: "区域影响力", score: 89 },
          { item: "市场占有率", score: 79 },
          { item: "技术更新水平", score: 94 },
          { item: "营业增长率", score: 100 },
        ]
      },
      { 
        key: '7', 
        firm: '新华三股份有限公司', 
        status: '中游', 
        matched: '88%', 
        score: [
          { item: "资产运营", score: 90 },
          { item: "区域影响力", score: 99 },
          { item: "市场占有率", score: 90 },
          { item: "技术更新水平", score: 90 },
          { item: "营业增长率", score: 90 },
        ]
      },
    ],
    matchInfo: hubeiPipei,
    safeScore: [      
      { key: 'hubeiPhySafe', img: hubeiPhySafe },
      { key: 'hubeiRunSafe', img: hubeiRunSafe },
      { key: 'hubeiDataSafe', img: hubeiDataSafe },
    ],
    namePosition: { top: '474px', left: '633px' },
    dotPosition: { top: '488px', left: '722px' },
    dotPositionAct: { top: '482px', left: '716px' },
  },
  beijing: {
    name: '北京市',
    shortName: '北京',
    company: [
      { 
        key: '8', 
        firm: '北京互动阳光科技有限公司', 
        status: '下游', 
        matched: '89%', 
        score: [
          { item: "资产运营", score: 99 },
          { item: "区域影响力", score: 98 },
          { item: "市场占有率", score: 97 },
          { item: "技术更新水平", score: 96 },
          { item: "营业增长率", score: 95 },
        ]
      },
      { 
        key: '9', 
        firm: '北京德科互联技术有限公司', 
        status: '下游', 
        matched: '87%', 
        score: [
          { item: "资产运营", score: 90 },
          { item: "区域影响力", score: 98 },
          { item: "市场占有率", score: 97 },
          { item: "技术更新水平", score: 94 },
          { item: "营业增长率", score: 94 },
        ]
      },
      { 
        key: '10', 
        firm: '北京中创科技有限公司', 
        status: '下游', 
        matched: '87%', 
        score: [
          { item: "资产运营", score: 99 },
          { item: "区域影响力", score: 78 },
          { item: "市场占有率", score: 87 },
          { item: "技术更新水平", score: 94 },
          { item: "营业增长率", score: 90 },
        ]
      },
      { 
        key: '11', 
        firm: '北京维康威视智能科技有限公司', 
        status: '中游', 
        matched: '85%', 
        score: [
          { item: "资产运营", score: 90 },
          { item: "区域影响力", score: 88 },
          { item: "市场占有率", score: 87 },
          { item: "技术更新水平", score: 84 },
          { item: "营业增长率", score: 100 },
        ]
      },
      { 
        key: '12', 
        firm: '北京神州数码有限公司', 
        status: '中游', 
        matched: '84%', 
        score: [
          { item: "资产运营", score: 80 },
          { item: "区域影响力", score: 98 },
          { item: "市场占有率", score: 97 },
          { item: "技术更新水平", score: 100 },
          { item: "营业增长率", score: 84 },
        ]
      },
      { 
        key: '13', 
        firm: '北京福音达计算机科技有限公司', 
        status: '中游', 
        matched: '81%', 
        score: [
          { item: "资产运营", score: 80 },
          { item: "区域影响力", score: 88 },
          { item: "市场占有率", score: 87 },
          { item: "技术更新水平", score: 84 },
          { item: "营业增长率", score: 84 },
        ]
      },
      { 
        key: '14', 
        firm: '北京金自天正智能控制股份有限公司', 
        status: '上游', 
        matched: '79%', 
        score: [
          { item: "资产运营", score: 90 },
          { item: "区域影响力", score: 78 },
          { item: "市场占有率", score: 87 },
          { item: "技术更新水平", score: 74 },
          { item: "营业增长率", score: 84 },
        ]
      },
    ],
    matchInfo: beijingPipei,
    safeScore: [      
      { key: 'beijingPhySafe', img: beijingPhySafe },
      { key: 'beijingRunSafe', img: beijingRunSafe },
      { key: 'beijingDataSafe', img: beijingDataSafe },
    ],
    namePosition: { top: '350px', left: '677px' },
    dotPosition: { top: '387px', left: '735px' },
    dotPositionAct: { top: '381px', left: '729px' }
  },
  shanghai: {
    name: '上海市',
    shortName: '上海',
    company: [
      { 
        key: '15', 
        firm: '上海延华智能科技(集团)股份有限公司', 
        status: '中游', 
        matched: '92%', 
        score: [
          { item: "资产运营", score: 90 },
          { item: "区域影响力", score: 98 },
          { item: "市场占有率", score: 97 },
          { item: "技术更新水平", score: 94 },
          { item: "营业增长率", score: 94 },
        ]
      },
      { 
        key: '16', 
        firm: '达丰（上海）电脑有限公司', 
        status: '中游', 
        matched: '91%', 
        score: [
          { item: "资产运营", score: 90 },
          { item: "区域影响力", score: 88 },
          { item: "市场占有率", score: 97 },
          { item: "技术更新水平", score: 84 },
          { item: "营业增长率", score: 94 },
        ]
      },
      { 
        key: '17', 
        firm: '惠普科技（上海）有限公司', 
        status: '中游', 
        matched: '89%', 
        score: [
          { item: "资产运营", score: 80 },
          { item: "区域影响力", score: 88 },
          { item: "市场占有率", score: 100 },
          { item: "技术更新水平", score: 84 },
          { item: "营业增长率", score: 84 },
        ]
      },
      { 
        key: '18', 
        firm: '方正科技集团股份有限公司', 
        status: '上游', 
        matched: '88%', 
        score: [
          { item: "资产运营", score: 98 },
          { item: "区域影响力", score: 90 },
          { item: "市场占有率", score: 94 },
          { item: "技术更新水平", score: 97 },
          { item: "营业增长率", score: 94 },
        ]
      },
      { 
        key: '19', 
        firm: '拉扎斯网络科技（上海）有限公司', 
        status: '下游', 
        matched: '87%', 
        score: [
          { item: "资产运营", score: 80 },
          { item: "区域影响力", score: 100 },
          { item: "市场占有率", score: 77 },
          { item: "技术更新水平", score: 94 },
          { item: "营业增长率", score: 97 },
        ]
      },
      { 
        key: '20', 
        firm: '上海盛大网络发展有限公司', 
        status: '下游', 
        matched: '82%', 
        score: [
          { item: "资产运营", score: 90 },
          { item: "区域影响力", score: 98 },
          { item: "市场占有率", score: 97 },
          { item: "技术更新水平", score: 94 },
          { item: "营业增长率", score: 94 },
        ]
      },
      { 
        key: '21', 
        firm: '携程旅游网络技术（上海）有限公司', 
        status: '下游', 
        matched: '79%', 
        score: [
          { item: "资产运营", score: 70 },
          { item: "区域影响力", score: 78 },
          { item: "市场占有率", score: 77 },
          { item: "技术更新水平", score: 74 },
          { item: "营业增长率", score: 74 },
        ]
      },
    ],
    matchInfo: shanghaiPipei,
    safeScore: [      
      { key: 'shanghaiPhySafe', img: shanghaiPhySafe },
      { key: 'shanghaiRunSafe', img: shanghaiRunSafe },
      { key: 'shanghaiDataSafe', img: shanghaiDataSafe },
    ],
    namePosition: { top: '443px', left: '726px' },
    dotPosition: { top: '479px', left: '786px' },
    dotPositionAct: { top: '473px', left: '780px' }
  }
}

export default data