import React, { Component } from "react";
import moment from "moment";
import "./index.less";
export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment().format("YYYY年MM月DD日"),
    };
  }

  render() {
    const { date } = this.state;

    return (
      <>
        <header>
          <div className="header_right">{date}</div>
        </header>
      </>
    );
  }
}
