import React, { Component } from "react";
import "./index.less";

let TIMER = null;

class index extends Component {
  constructor() {
    super();
    this.state = {
      currentRow: 0,
    };
  }

  componentWillUnmount() {
    clearInterval(TIMER);
  }

  componentDidUpdate(prevProps, prevState) {
    const { curKey } = prevProps;
    const { curKey: curCurKey } = this.props;
    const { currentRow } = prevState;
    const { currentRow: curRow } = this.state;
    if (currentRow === curRow && curKey !== curCurKey) {
      this.reset(curCurKey);
    }
  }
  reset = (curCurKey) => {
    clearInterval(TIMER);
    this.setState({
      currentRow: curCurKey,
    });
  };
  changeRow = (i) => {
    clearInterval(TIMER);
    const { onChange } = this.props;
    if (onChange) {
      onChange(Number(i));
    }
    this.setState({
      currentRow: Number(i),
    });
  };

  shiftConf = (i) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(Number(i));
    }
    this.setState({
      currentRow: Number(i),
    });
  };

  autoRun = (max) => {
    clearInterval(TIMER);
    const { time } = this.props;
    const { currentRow } = this.state;
    const nextRow = currentRow === max ? 0 : currentRow + 1;

    TIMER = setInterval(() => {
      this.shiftConf(nextRow);
    }, time || 3000);
  };

  render() {
    const { tableData, columns } = this.props;
    const { currentRow } = this.state;
    const dataIndex = columns.map((item, index) => {
      return item.dataIndex;
    });

    const tr = tableData.map((ele, i) => {
      const keys = Object.keys(ele);
      return (
        <tr className={currentRow === i ? "clickRowStyl" : ""} key={i}>
          {keys.map((item, index) => {
            if (dataIndex.indexOf(item) !== -1) {
              return (
                <td onClick={() => this.changeRow(i)} key={index}>
                  {ele[item]}
                </td>
              );
            }
            return null;
          })}
        </tr>
      );
    });

    this.autoRun(tableData.length - 1);

    return (
      <div className="myTable">
        <table>
          <thead>
            <tr>
              {columns.map((item, index) => {
                return <th key={item.key}>{item.title}</th>;
              })}
            </tr>
          </thead>
          <tbody>{tr}</tbody>
        </table>
      </div>
    );
  }
}

export default index;
