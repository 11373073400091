const data = [
  [
    { name: '用于电脑的图形用户界面', company: '中云网安科技有限公司', matchDegree: '99.41'},
    { name: '手机的应用软件界面', company: '中云网安科技有限公司', matchDegree: '92.88'},
  ],
  [
    { name: '带交互界面的手机（Be My Eyes）', company: '中云网安科技有限公司', matchDegree: '92.12'},
    // { name: '一种云环境下高效的隐私保护密文连接访问操作验证方法', company: '中云网安科技有限公司', matchDegree: '88.71' },
    { name: '一种云环境下高效的隐私保护密文查询方法', company: '中云网安科技有限公司', matchDegree: '88.38'},
  ],
  [    
    { name: '一种OpenFlow网络安全检测方法及系统', company: '中云网安科技有限公司', matchDegree: '86.79'},
    { name: '一种地理空间数据的用户隐私保护方法及系统', company: '中云网安科技有限公司', matchDegree: '86.02'},
  ],
  [    
    { name: '分布式网络动态安全保护系统', company: '中云网安科技有限公司', matchDegree: '83.59'},
    { name: '一种针对交流困难患者的可视听交互方法和装置', company: '手之声信息科技有限公司', matchDegree: '83.99'},
  ],
  [    
    { name: '一种实现汉语近自然语言查询接口的系统', company: '手之声信息科技有限公司', matchDegree: '82.49'},
    { name: '手语翻译器', company: '手之声信息科技有限公司', matchDegree: '80.38'},
  ],
  // [
  //   // { name: '基于语义计算的听力或语言障碍人士交流系统及其方法', company: '手之声信息科技有限公司', matchDegree: '81.86'},
  //   { name: '手语翻译器', company: '手之声信息科技有限公司', matchDegree: '80.38'},
  // ],
  // [
  //   // { name: '一种基于双声道音频多普勒效应的手势识别方法及系统', company: '手之声信息科技有限公司', matchDegree: '79.98'},
  //   // { name: '基于多中心的手语在线客服分配管理方法、装置及系统', company: '手之声信息科技有限公司', matchDegree: '76.83'},
  // ],
  [
    { name: '种基于多源信息融合的手语识别方法', company: '手之声信息科技有限公司', matchDegree: '75.65'},
    { name: '一种基于多源信息融合的手语识别方法', company: '手之声信息科技有限公司', matchDegree: '75.65'},
  ],
];

export default data