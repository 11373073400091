import React, { Component } from "react";
// import { Table } from "antd";
import CenterStatistic from "../../components/CenterStatistic";
import Header from "../../components/Header";
import Screen1RightBottom from "../../components/Screen1RightBottom";
import SCREEN1DATA from "../../morkData/qidiProviceData";
import Screen1LeftBottom from "../../components/Screen1LeftBottom";
import MyTable from "../../components/MyTable";
import "./index.less";

let TIMER = null;
class index extends Component {
  constructor() {
    super();
    const mapDotData = Object.keys(SCREEN1DATA).map((key) => {
      const item = SCREEN1DATA[key];
      return {
        key,
        namePosition: item.namePosition,
        name: item.shortName,
        dotPosition: item.dotPosition,
        dotPositionAct: item.dotPositionAct,
      };
    });
    this.state = {
      currentCity: "湖北",
      curAreaKey: "hubei",
      curCompanyKey: 0,
      mapDotData,
      statisticData: [
        { title: "本月新增企业", num: 121, unit: "(家)" },
        { title: "在园企业", num: 649, unit: "(家)" },
        { title: "上市企业", num: 104, unit: "(家)" },
        { title: "企业资质", num: 28501, unit: "(项)" },
      ],
      columns: [
        {
          title: "企业名称",
          dataIndex: "firm",
          key: "firm",
        },
        {
          title: "产业地位",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "匹配度",
          dataIndex: "matched",
          key: "matched",
        },
      ],
    };
  }

  componentWillUnmount() {
    clearInterval(TIMER);
  }

  handleAreaClick = (key) => {
    clearInterval(TIMER);
    this.setState({ curAreaKey: key });
  };

  companyChange = (index) => {
    clearInterval(TIMER);
    this.setState({ curCompanyKey: index });
  };

  shiftConf = (key) => {
    this.setState({ curAreaKey: key });
  };

  autoRun = (curKey) => {
    clearInterval(TIMER);
    const { mapDotData } = this.state;
    const keyNameArr = mapDotData.map((ele) => ele.key);
    const max = mapDotData.length - 1;
    const curIndex = keyNameArr.indexOf(curKey);
    const nextIndex = curIndex === max ? 0 : curIndex + 1;
    const nextKey = keyNameArr[nextIndex];

    TIMER = setInterval(() => {
      this.shiftConf(nextKey);
    }, 21000);
  };

  render() {
    const {
      statisticData,
      // rightBottomData,
      columns,
      // currentCity,
      curAreaKey,
      mapDotData,
      curCompanyKey,
    } = this.state;
    const target = SCREEN1DATA[curAreaKey];
    console.log(mapDotData);

    const areaPositionList = mapDotData.map((ele) => {
      const { key, namePosition, name, dotPosition, dotPositionAct } = ele;
      const isactivity = key === curAreaKey;
      const curStyle = isactivity ? dotPositionAct : dotPosition;
      return (
        <div key={key}>
          <div
            className="mapAreaName"
            isactivity={String(isactivity)}
            style={{ ...namePosition }}
            onClick={() => this.handleAreaClick(key)}
          >
            {name}
          </div>
          <div
            className="mapAreaDot"
            isactivity={String(isactivity)}
            style={{ ...curStyle }}
            onClick={() => this.handleAreaClick(key)}
          />
        </div>
      );
    });

    const curCompany = target.company[curCompanyKey] || {};
    const rightBottomData = curCompany.score || [];
    this.autoRun(curAreaKey);

    return (
      <div>
        <div className="wrapper1">
          <Header></Header>
          <div className="screen1_content">
            <div className="content_center">
              <CenterStatistic statisticData={statisticData} />
            </div>
            {/* <div className="selectMap">
              <div
                className={currentCity === "湖北" ? "point active" : "point"}
              ></div>
            </div> */}
            <section className="l_top">
              <div className="title">{`${target.name}产业链匹配企业情况`}</div>
              <div className="info">
                <div className="matchInfoImgArea">
                  <img src={target.matchInfo} alt="matchInfo" />
                </div>
              </div>
            </section>
            <section className="r_top">
              <div className="title">{`${target.name}产业链匹配企业情况`}</div>
              <div className="info">
                <MyTable
                  tableData={target.company}
                  columns={columns}
                  onChange={this.companyChange}
                />
              </div>
            </section>
            <section className="l_bottom">
              <div className="title">{`${target.name}信息安全市场评分`}</div>
              <div className="info">
                <Screen1LeftBottom
                  data={target.safeScore}
                  curKey={curAreaKey}
                />
              </div>
            </section>
            <section className="r_bottom">
              <div className="title">{curCompany.firm}</div>
              <div className="info">
                <div>
                  <Screen1RightBottom data={rightBottomData} />
                </div>
              </div>
            </section>
            <div>
              <div>{areaPositionList}</div>
              {/* <div className="activityDot" /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default index;
