import React, { Component } from "react";

export default class index extends Component {
  render() {
    const { statisticData } = this.props;
    const statistic = statisticData.map((item, index) => {
      return (
        <div key={index} className="center_item">
          <div className="title">{item.title}</div>
          <div className="unit">{item.unit}</div>
          <div className="text">{item.num}</div>
        </div>
      );
    });
    return <>{statistic}</>;
  }
}
